import React from 'react';

interface Props {
  audioFile: string;
}

const Player = ({ audioFile }: Props) => (
  <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}
  >
    <iframe
      src={audioFile}
      height="102px"
      width="75%"
      frameBorder="0"
      scrolling="no"
      title="Player"
      style={{
        margin: '1.5em 0',
      }}
    />
  </div>
);

export default Player;
