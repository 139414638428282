import React from 'react';
import './Episode.scss';
import { Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Typography, { Kind as TypographyKind } from './Typography';
import Player from './Player';

export enum Kind {
  Full,
  Brief,
  List,
}

interface Props {
  kind?: Kind;
  title: string;
  url: string;
  pronoun: string;
  website: string;
}

const Header = ({ episodeNumber, title }: any) => (
  <header>
    <Typography kind={TypographyKind.EntryTitle}>
      <div>
        #
        {episodeNumber}
      </div>
      <div>{title}</div>
    </Typography>
  </header>
);

const Details = ({
  slug, date, website, pronoun,
}: any) => (
  <ul className="details">
    {!!date && <li className="date">{date}</li>}
    {!!slug && (
      <li className="permalink">
        <Link to={slug}>View Shownotes</Link>
      </li>
    )}
    {!!website && (
      <li className="homepage">
        <a href={website}>
          {pronoun}
          {' '}
          website
        </a>
      </li>
    )}
  </ul>
);

/* eslint-disable react/destructuring-assignment */
const EpisodeFull = (props: any) => (
  <article className="episode episodeFull" id={`ep-${props.episodeNumber}`}>
    <Player audioFile={props.url} />
    <Header {...props} />
    <section>
      <MDXRenderer>{props.body}</MDXRenderer>
    </section>
    <Details date={props.date} website={props.website} pronoun={props.pronoun} />
  </article>
);

const EpisodeBrief = (props: any) => (
  <article className="episode episodeBrief">
    <Header {...props} />
    <Details {...props} />
  </article>
);

const EpisodeList = (props: any) => (
  <article className="episode episodeListItem">
    <Header {...props} />
    <Details slug={props.slug} date={props.date} />
  </article>
);

const Episode = ({ kind, ...props }: Props) => {
  switch (kind) {
    case Kind.Full:
      return <EpisodeFull {...props} />;
    case Kind.List:
      return <EpisodeList {...props} />;
    default:
      return <EpisodeBrief {...props} />;
  }
};
/* eslint-enable react/destructuring-assignment */

Episode.defaultProps = {
  kind: Kind.Brief,
};

export default Episode;
