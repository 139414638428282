import * as React from 'react';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Episode, { Kind } from '../components/Episode';
import Typography, { Kind as TypographyKind } from '../components/Typography';

import Colors from '../components/Colors';
import Layout from '../components/Layout';
import SEO from '../components/seo';

const Archive = ({ data }) => (
  <Layout>
    <SEO title="Archive of Episodes" />
    <Typography
      kind={TypographyKind.LargestHeader}
      style={{
        color: Colors.orange__300,
      }}
    >
      All Episodes
    </Typography>
    {data.allMdx.edges.map((item) => (
      <Episode
        key={item.node.id}
        kind={Kind.List}
        {...item.node}
        {...item.node.frontmatter}
        {...item.node.fields}
      />
    ))}
  </Layout>
);
Archive.propTypes = {
  data: PropTypes.object,
};
Archive.defaultProps = {
  data: {},
};

export const pageQuery = graphql`
  {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fields: { slug: { regex: "/^/\\d+/" }}, isFuture: { eq: false} }
    ) {
      edges {
        node {
          id
          slug
          body
          frontmatter {
            title
            website
            pronoun
            audioFile
            episodeNumber
            episodeArt
            date(formatString: "DD MMM YYYY")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default Archive;
